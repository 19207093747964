/* -----------------------------

SURVOL DES RÉFÉRENCES

----------------------------- */


var itemRealisations = document.querySelectorAll(".itemRealisation");
var listeRealisation = document.querySelectorAll(".listeRealisation");

if(listeRealisation.length){

  console.log('JS : survol des references');


/////////////////////////////
// GESTION DIMENSION DE LA FENÊTRE
var intFrameWidth, intFrameHeight;
function reportWindowSize(){
  intFrameWidth = window.innerWidth;
  intFrameHeight = window.innerHeight;  
  //console.log("largeur fenêtre : " + intFrameWidth + " / hauteur fenêtre :" + intFrameHeight);
}
reportWindowSize();
window.addEventListener('resize', reportWindowSize);


/////////////////////////////
//Gestion du survol pour chaque références
itemRealisations.forEach(function(item){


  var imageRealisation = item.querySelector(".imgRealisation");

  item.addEventListener("mouseenter", function(e){
    // imageRealisation.classList.toggle("imgRealInvisible");
    imageRealisation.classList.toggle("imgRealVisible");
  });

  item.addEventListener("mousemove", function(e){

    //INFOS SUR LES COORDONNEES
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSSOM_View/Coordinate_systems

    //OFFSET X ou Y : position de la souris par rapport à l'élément sélectionné
    // console.log("offset x: " + e.offsetX + " / offset y: " + e.offsetY);

    //CLIENT X ou Y : position de la souris par rapport à la zone visible
    // console.log("client x: " + e.clientX + " / client y: " + e.clientY);

    //PAGE X ou Y : position de la souris par rapport à la page entière
    //console.log("page x: " + e.pageX + " / page y: " + e.pageY);


    // Calcul position x de l'image
    if (e.pageX < (intFrameWidth - (imageRealisation.offsetWidth + 80))) {
      positionX = e.pageX + 20;
      // console.log("casX 1 : " + (e.pageX < (intFrameWidth / 2)) );
    }else{
      positionX = (e.pageX - imageRealisation.offsetWidth) - 20;
      // console.log("casX 2 : " + (e.pageX < (intFrameWidth / 2)) );
      // console.log("page x: " + e.pageX + " - imageRealisation.offsetWidth : " + imageRealisation.offsetWidth + " = positionX: " + positionX );
    }

    // Calcul position Y de l'image
    var posItem = item.getBoundingClientRect();
    var posItemX = posItem.left;
    var posItemY = posItem.top;

    if (posItemY < (intFrameHeight / 2)) {
      positionY = e.pageY + 20;
      //console.log("casY 1 : " + (posItemY < (intFrameHeight - (imageRealisation.offsetHeight + 40))) );
    }else{
      //console.log("casY 2 : " + (posItemY < (intFrameHeight - (imageRealisation.offsetHeight + 40))) );
      positionY = (e.pageY - imageRealisation.offsetHeight) - 20;
      //console.log("item.clientY : " + posItemY);
      //console.log("page Y: " + e.pageY + " - imageRealisation.offsetHeight : " + imageRealisation.offsetHeight + " = positionY: " + positionY );
    }

    //Attribution position X et Y
    imageRealisation.style.left = positionX + "px";
    imageRealisation.style.top = positionY + "px";



    //console.log("pos left : " + imageRealisation.style.left + " / pos top : " + imageRealisation.style.top);
  });

  item.addEventListener("mouseleave", function(e){
    //console.log("out");
    // imageRealisation.classList.toggle("imgRealInvisible");
    imageRealisation.classList.toggle("imgRealVisible");
  });
});


}
else{
  console.log('pas de réalisation');
}