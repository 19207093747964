/* -----------------------------

FILE UPLOAD

https://tympanus.net/Tutorials/CustomFileInputs/

----------------------------- */

var inputs = document.querySelectorAll( 'input[type="file"]' );
inputs.forEach(function( input ){

	var conteneurFileInput = input.parentNode;
    var fileSelected = document.createElement("span");
    fileSelected.classList.add('nomFichier');
    conteneurFileInput.appendChild(fileSelected);

    var text;

	input.addEventListener( 'change', function( e )
	{
		var fileName = '';
		if( this.files && this.files.length > 1 ){
			fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
		}
		else{
			fileName = e.target.value.split( '\\' ).pop();
		}

		if( fileName ){
			    fileSelected.innerHTML = fileName;
		}

	});

});
