/* -----------------------------

SCROLL ANIMÉ DU HEADER
//https://gomakethings.com/how-to-scroll-an-element-into-view-with-vanilla-js/

----------------------------- */

//pageCover est défini das z0_functions.js
if (pageCover) {

//AU CLIC : scroll animé
var clicDiapoEntete = document.querySelector(".pageCover .swiper-container");
var clicTitreCover = document.querySelector(".pageCover .titreCover");
var clicImgEntete = document.querySelector(".pageCover .imgEntete");
var arriveeScroll = document.querySelector(".pageCover").offsetHeight;
//var arriveeScroll = document.querySelector(".pageContent");

var scrollToContent = function(clicItem, heightToScroll){

  clicItem.addEventListener("click", function(){
    //arriveeScroll.scrollIntoView();

    // Polyfill pour safari
    // http://iamdustan.com/smoothscroll/
    window.scroll({ top: heightToScroll, left: 0, behavior: 'smooth' });
  });

}

if (clicDiapoEntete) {
scrollToContent(clicDiapoEntete, arriveeScroll);
}
if (clicTitreCover) {
scrollToContent(clicTitreCover, arriveeScroll);
}
if (clicImgEntete) {
scrollToContent(clicImgEntete, arriveeScroll);
}

}