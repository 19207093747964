/* -----------------------------

DIAPORAMA SWIPER.JS
https://swiperjs.com/get-started

----------------------------- */

//DIAPO CORPS DE PAGE
var blocDiaporama = document.querySelectorAll(".bloc-diaporama");

if(blocDiaporama.length){
  console.log('JS : diapo page actif');

blocDiaporama.forEach(function(blocDiapo){

var diapo = blocDiapo.querySelector('.swiper-container');

const swiper = new Swiper(diapo, {

  // Optional parameters
  loop: true,
  // cssMode: true, 
  
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.clicNext',
    prevEl: '.clicPrev',
  },

});

}); //END FOREACH

}else{
  console.log('Pas de diapo page');
}


// DIAPORAMA ENTETE
var diapoEntete = document.querySelector(".pageCover .swiper-container");

if(blocDiaporama){
  console.log('JS : diapo entete de page');

const swiper = new Swiper(diapoEntete, {

  // Optional parameters
  loop: true,
  speed: 900,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  autoplay: {
   delay: 5000,
 },
  // cssMode: true, 
  

});

}else{
  console.log('Pas de diapo entete de page');
}