/* -----------------------------

RECRUTEMENT

----------------------------- */
var page = document.querySelector("html");


/////////////////////////////
// POP-UP CANDIDATURE SPONTANÉE
var btnSpontanee = document.getElementById('btnSpontanee');
if (btnSpontanee) {

  console.log("module candidature spontanée");

  btnSpontanee.addEventListener('click', ouvrirPopUp);

function ouvrirPopUp(){
  var popUp = document.querySelector('.fSpontanee');

  popUp.classList.add('ouvert');
  popUp.classList.remove('fermer');
  popUp.classList.remove('hideOutput');
  page.classList.add("noScroll");

  // console.log("ouvrirPopUp !");

  // FERMER POP-UP
  var btnClose = popUp.querySelector('.closeBtn');
  
  btnClose.addEventListener('click', function(){
    popUp.classList.remove('ouvert');
    popUp.classList.add('fermer');
    page.classList.remove("noScroll");

    var reponseForm = popUp.querySelectorAll('.wpcf7-response-output');
    reponseForm.forEach(function(reponse){
      // reponse.innerHTML= '';
      // reponse.style.background = 'transparent';
      popUp.classList.add('hideOutput');
      console.log('effacer reponse form spontanée');
    })
  });

};

}else{
  console.log("pas de candidature spontanée");
}


/////////////////////////////
// DEPLIER LES ANNONCES
var listeOffre = document.querySelectorAll('.listeOffre')
if (listeOffre.length) {
  console.log("deplier les annonces activé");

  var listesAnnonce = document.querySelectorAll('.annonce');

  listesAnnonce.forEach(function(annonce){

      var ligneAnnonce = annonce.querySelector('.ligneAnnonce');
      var contenuAnnonce = annonce.querySelector('.contenuAnnonce');

      ligneAnnonce.addEventListener('click', function(e){
          e.preventDefault();
          annonce.classList.toggle('ouvrir');
      });

  })

}else{
  console.log("pas de deplier les annonces");
}


/////////////////////////////
// POP-UP CANDIDATURE ANNONCE
var offreEmploi = document.querySelectorAll('.annonce');

if (offreEmploi.length) {
  console.log("Pop-up candidature annonce activé");


offreEmploi.forEach(function(offre){

  var btnPostuler = offre.querySelector('.btnPostuler');

  btnPostuler.addEventListener('click', ouvrirPopUp2);

  function ouvrirPopUp2(){
    var popUp2 = offre.querySelector('.fAnnonce');

    popUp2.classList.add('ouvert');
    popUp2.classList.remove('fermer');
    popUp2.classList.remove('hideOutput');
    page.classList.add("noScroll");

    // console.log("ouvrirPopUp22 !");

    // FERMER POP-UP
    var btnClose = popUp2.querySelector('.closeBtn');
    
    btnClose.addEventListener('click', function(){
      popUp2.classList.remove('ouvert');
      popUp2.classList.add('fermer');
      page.classList.remove("noScroll");

        var reponseForm = popUp2.querySelectorAll('.wpcf7-response-output');
        reponseForm.forEach(function(reponse){
          // reponse.innerHTML= '';
          // reponse.style.background = 'transparent';
          popUp2.classList.add('hideOutput');
          console.log('effacer reponse form annonce');
        })
      });
  };


});


}else{
  console.log("pas de candidature annonce");
}

/////////////////////////////
// MENU PARTAGE (offre d'emploi)

var menuPartage = document.querySelectorAll('.menuPartage');

if(menuPartage.length){
  console.log("menu partage activé");

menuPartage.forEach(function(partage){

  //Afficher le menu de partage
  var btnPartage = partage.querySelector('.btnPartage');
  btnPartage.addEventListener('click', function(){
    btnPartage.classList.add('hide');
  });

  //Bouton copy
  var copyButton = partage.querySelector('.copyButton');
  var txtToCopy = partage.querySelector('.txtToCopy');
  copyButton.addEventListener('click', function(){
      var temp = document.createElement("INPUT");
      temp.value = txtToCopy.innerHTML;
      document.body.appendChild(temp);
      temp.select();
      document.execCommand("copy");
      temp.remove();
      // console.log("Text Copied!");
      txtToCopy.innerHTML = "URL copiée !";
  });
});

}else{
  console.log("pas de menu de partage");
}

