console.log("JS OK 17h00");

/////////////////////////////
// LAZYLOAD IMAGE : réglages supplémentaires
// https://github.com/aFarkas/lazysizes
lazySizesConfig.preloadAfterLoad = true;


/////////////////////////////
// MENU MOBILE

var menuHeader = document.querySelector("#header");
var btnMobile = menuHeader.querySelector("#btnMenu");
var iconeMobile = menuHeader.querySelector("#nav-toggle");
var navMobile = menuHeader.querySelector("#nav");
var pageHTML = document.querySelector("html");

  //Ouvrir/fermer le menu
  btnMobile.addEventListener("click", function() {
    // console.log("click menu");
    iconeMobile.classList.toggle("active");
    navMobile.classList.toggle("open");
    pageHTML.classList.toggle("noScroll");
    menuHeader.classList.toggle("navOpen");
  });

  // Fermer le menu au clic sur un lien
  var lienMenus = menuHeader.querySelectorAll("li:not(.menu-item-has-children) a");

  lienMenus.forEach(function(lienMenu){
      lienMenu.addEventListener("click", function(){
          iconeMobile.classList.remove("active");
          navMobile.classList.remove("open");          
          pageHTML.classList.remove("noScroll");
          menuHeader.classList.remove("navOpen");
      })
  });

//FONCTIONNEMENT SOUS MENU CAT
var btnSousMenu = menuHeader.querySelector(".menu-item-has-children");
var sousMenu = menuHeader.querySelector(".sub-menu");


btnSousMenu.addEventListener("click", function(){
 sousMenu.classList.toggle("ouvert");
 btnSousMenu.classList.toggle("ouvert");
});


/////////////////////////////
// MENU : CACHER AU SCROLL DOWN
// https://codingreflections.com/hide-header-on-scroll-down/

  var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;

  var pageCover = document.querySelector(".pageCover");
  if (pageCover) {
    console.log("1");
    var hauteurCover = pageCover.offsetHeight;
  // var hauteurCoverSeule = document.querySelector(".pageCover").offsetHeight;
  }else{
    var hauteurCover = 0;
    console.log("2");
  }


  var checkScroll = function() {

    /*
    ** Find the direction of scroll
    ** 0 - initial, 1 - up, 2 - down
    */

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) { 
      //scrolled DOWN
      direction = 2;
    }
    else if (curScroll < prevScroll) { 
      //scrolled UP
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }


    // MENU FLOTTANT OU NORMAL
    if (curScroll < hauteurCover) { 
            menuHeader.classList.remove("MenuFlottant");
    }else if (curScroll > hauteurCover){
            menuHeader.classList.add("MenuFlottant");
    }


    prevScroll = curScroll;

  };

  var toggleHeader = function(direction, curScroll) {
    if (direction === 2 && curScroll > hauteurCover) { 
      // console.log('cas 2 - menu caché');
      //hauteurCover = the height of your header
      header.classList.add("hide");
      prevDirection = direction;
    }
    else if (direction === 1) {
      // console.log('cas 1 - menu visible');
      header.classList.remove("hide");
      prevDirection = direction;
    }
  };

  window.addEventListener("scroll", checkScroll);

